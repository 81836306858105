@import 'mssvariables';

html,
body {
  font-size: 0.85rem !important;
  font-family: $font-family-sans-serif;
  font-weight: 400;
  background: theme-color-2022('2022--light');
}

h1,
.h1 {
  font-size: 2.25rem;
  font-weight: 400;
}

h2,
.h2 {
  font-size: 1.5rem;
  font-weight: 400;
}

h3,
.h3 {
  font-size: 1.3rem;
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

.card {
  border-radius: 0;
  border: none;
  color: theme-color-2022('2022--dark');

  .card-header {
    border-radius: 0;
    border: none;
    background-color: theme-color-2022('2022--primary-lighter-still');
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.tab-active {
  color: white !important;
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (max-width: $screen-md) {
  .card {
    max-width: none;
  }
}

.separator {
  height: 1px;
  background-color: theme-color-2022('2022--secondary');
  margin: 15px -15px;
}

.lender-logo img {
  max-width: 70%;
  max-height: 65px;
  width: auto;
  height: auto;
}

.text-small {
  font-size: 0.9rem;
}

.modal-with-progressbar {
  margin-top: 4rem;
  padding-right: 0;
  padding-left: 1rem;
}

.sticky-left {
  position: sticky;
  left: 0;
}

.sticky-right {
  position: sticky;
  right: 0;
}

.dropdown-menu {
  z-index: 2000;
}

.dropdown-item:active {
  background-color: theme-color-2022('2022--secondary');
  color: theme-color-2022('2022--dark');
}

.sf-shepherd {
  position: absolute;
  z-index: 9999;
  display: grid;
  grid: min-content min-content / 1fr 1fr;
  grid-template-areas:
    "body body"
    "prev next";
  max-width: 280px;
  border: 1px solid theme-color-2022('2022--primary');
  border-radius: 4px;
  padding: 12px;
  font-size: 0.95rem;
  background: theme-color-2022('2022--white');
  color: theme-color-2022('2022--black');
  box-shadow: 2px 2px 14px rgba(0 0 0 / 40%);

  > .sf-shepherd-arrow {
    position: absolute;
    width: 14px;
    height: 14px;
    background: theme-color-2022('2022--white');
    border: 1px solid theme-color-2022('2022--primary');
    border-bottom: none;
    border-left: none;
    transform: rotate(45deg);
  }

  > .sf-shepherd-body {
    grid-area: body;
    margin-right: 22px; // right margin is larger to account for the close button
  }

  > .sf-shepherd-body > p:last-child {
    margin-bottom: 0;
  }

  > .sf-shepherd-prev-btn, .sf-shepherd-next-btn {
    margin-top: 8px;
  }

  > .sf-shepherd-prev-btn {
    grid-area: prev;
  }

  > .sf-shepherd-next-btn {
    grid-area: next;
  }

  > .sf-shepherd-close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.sf-shepherd-highlight {
  position: fixed;
  z-index: 9998;
  border: 4px solid theme-color-2022('2022--primary');
  border-radius: 4px;
  box-shadow: 0 0 12px 1px theme-color-2022('2022--primary');
}

.sf-shepherd,
.sf-shepherd-highlight {
  transition: opacity ease-out 250ms;
  opacity: 0;

  &.show {
    opacity: 1;
  }
}
