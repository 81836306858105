@use 'sass:map';

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'bootstrap/scss/functions';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'bootstrap/scss/variables';

/* Colours */
$custom-colors: (
  /* Theme */
  '2022--primary': #ec6084,
  '2022--primary-light': #fab0b6,
  '2022--primary-lighter': #f6b2b6,
  '2022--primary-lighter-still': #f2c0cb,
  '2022--primary-lightest': #fbd8da,
  '2022--primary-darkest': #e22922,
  '2022--secondary': #71cbf4,
  '2022--secondary-seashell': lighten(#71cbf4, 23%),
  '2022--secondary-light': #e3f2fd,
  '2022--secondary-darkest': #0076d6,
  '2022--light-blue': #61c9f0,
  /* Status */
  '2022--success': #028844,
  '2022--info': #64cbf0,
  '2022--warning': #ffd500,
  '2022--danger': #e22922,
  '2022--danger-light': #ffeaec,
  '2022--danger-dark': #a81815,
  '2022--disabled': #b0b0b0,
  /* Shades */
  '2022--white': #fff,
  '2022--almost-white': #fdfdfd,
  '2022--light': #f8f7f5,
  '2022--light-1': #d7d7d7,
  '2022--lighter': #f5f3f2,
  '2022--light-grey': #f6f6f6,
  '2022--grey': #929292,
  '2022--dark-grey': #575757,
  '2022--dark': #333,
  '2022--black': #000,
  '2022--light-pink': #f5eaea
);
$theme-colors: map-merge($theme-colors, $custom-colors);

@function theme-color-2022($key: 'primary') {
  @return map-get($custom-colors, $key);
}

/* Spacing */
$additional-spacers: (
  '6': 5rem,
  '7': 8rem,
  '8': 10rem,
  '9': 12.5rem,
  '10': 15rem
);
$spacers: map-merge($spacers, $additional-spacers);

/* Add negative variants of all the spacers */
@each $item, $space in $spacers {
  $spacers: map.set($spacers, 'neg' + $item, -$space);
}

/* General */
$font-family-sans-serif: 'Roboto', arial, helvetica, sans-serif;
$border-radius: 0.15rem;

/* Buttons */
$btn-border-radius: 0.1rem;

/* Forms */
$input-border-radius: 0.15rem;
$input-border-color: theme-color-2022('2022--grey');
$input-padding-y: 0.565rem;

$form-select-font-family: $form-select-font-family;
$form-select-border-radius: $input-border-radius;
$form-select-border-color: $input-border-color;
$form-select-padding-y: 0.565rem;
$form-select-padding-x: 1rem;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $input-bg;
$input-group-addon-border-color: $input-border-color;

$form-check-input-indeterminate-bg-color: theme-color-2022('2022--secondary');

/* Alerts */
$alert-border-width: 0;
$alert-border-radius: $border-radius;

/* Modals */
$modal-content-border-radius: $border-radius;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);

/* Cards */
$card-border-radius: 0;
