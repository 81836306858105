@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import './lib/_mssvariables.scss';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);
$enable-dark-mode: false;

@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/utilities/api';
@import './lib/colour';
@import './lib/forms';
@import './lib/global';
@import './lib/modal';
@import './lib/toasts';
@import './lib/placeholder';

/* -----------------------  */

/* Additional rules (2022)  */

/* -----------------------  */
.font-trebuchet {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.alert.alert-danger {
  /* Default bootstrap alert variables are hard to get precise colours with, so we'll just overwrite them here */
  background: theme-color-2022('2022--danger-light');
  color: theme-color-2022('2022--danger-dark');
}

.input-group-text,
label {
  /* Bootstrap does not seem to apply the input font family to input groups */
  font-family: $input-font-family;
}

.input-group.disabled > .input-group-text {
  background: $input-disabled-bg;
}

.form-control:not(:focus),
.form-select:not(:focus),
.custom-radio-label {
  /* Disable bootstrap shadow as it makes addons look weird with new style */
  box-shadow: none !important;
}

/* Remove inner borders between inputs and textual addons */
.input-group > .form-control:not(:first-child),
.input-group > .input-group-text:not(:first-child) {
  border-left: none;
}

.input-group > .form-control:not(:last-child),
.input-group > .input-group-text:not(:last-child) {
  border-right: none;
}

/* Allow .is-invalid to be applied to .input-groups (or parents of .input-groups) to also show the addons as invalid */
.is-invalid .input-group > *,
.is-invalid.input-group > * {
  color: theme-color-2022('2022--danger');
  border-color: theme-color-2022('2022--danger');
}

/* Global modal header color */
.modal .modal-header {
  background-color: theme-color-2022('2022--primary');
  color: theme-color-2022('dark');
}

.pointer-events-none {
  pointer-events: none;
}

/* -------------------------  */

/* Additional rules (legacy) */

/*   (remove if possible) */

/* -------------------------  */
main {
  min-height: 600px;
}

button.disabled,
button:disabled {
  cursor: not-allowed;
  box-shadow: none !important;
}

::-ms-clear {
  display: none;
}

.add-right-space {
  overflow-y: scroll;
}

.match-tooltip {
  font-size: 0.8rem;
  max-width: 5.4375rem;
}

.custom-tooltip {
  transition-duration: 75ms;

  &.show {
    opacity: 1 !important;
  }

  .tooltip-inner {
    border: 1px solid theme-color-2022('2022--secondary');
    border-radius: 4px;
    padding: 5px;
    background: theme-color-2022('2022--white');
    color: theme-color-2022('2022--black');
  }

  .arrow::before {
    border: none;
  }
}

.rio-tooltip {
  min-width: 300px;
  padding: 10px;
}

.fees-tooltip {
  .tooltip-inner {
    min-width: 300px;
  }
}

.erc-tooltip {
  .tooltip-inner {
    width: 175px;
    padding: 10px;
  }
}

.overrides-tooltip {
  // Fix fading in/out constantly when mouse is positioned over the element in such a way that also overlaps the tooltip
  pointer-events: none;

  .tooltip-inner {
    min-width: 240px;
  }
}

.btn-signpost {
  background: theme-color-2022('2022--secondary') !important;
  color: theme-color-2022('2022--white');
  border-radius: 0;
  padding: 0;
  min-width: 0;
  width: 0;
  height: 20px;
  position: relative;
  border-right: 0;
  transition: 200ms linear;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8;
    color: theme-color-2022('2022--white');
  }

  &::after {
    content: '';
    color: theme-color-2022('2022--secondary') !important;
    position: absolute;
    top: -1px;
    left: 100%;
    height: 0;
    width: 0;
    border-width: 10px;
    border-style: solid;
    border-color: theme-color-2022('2022--secondary');
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}

.btn-signpost-reversed {
  @extend .btn-signpost;

  &::after {
    left: -22px;
    transform: rotate(180deg);
  }
}

.btn-page {
  min-width: 20px;
  height: 20px;
  border-left: 1px solid theme-color-2022('2022--secondary');
  border-right: 1px solid theme-color-2022('2022--secondary');
  color: theme-color-2022('2022--black') !important;
  border-radius: 0;
  margin: 0 2px;
  padding: 0;

  &:hover {
    color: theme-color-2022('2022--black') !important;
    background: theme-color-2022('2022--white') !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-page-current {
  @extend .btn-page;

  background: theme-color-2022('2022--secondary') !important;
  color: theme-color-2022('2022--black') !important;
  pointer-events: none;
}

.disabled > a {
  border-color: theme-color-2022('2022--secondary') !important;
  color: theme-color-2022('2022--secondary');
}

.pagination {
  margin-bottom: 0;

  li {
    a {
      @extend .btn-page;

      line-height: 20px;
      margin-left: 0;
      text-align: center;
      border-radius: 0 !important;

      &:hover {
        border-color: transparent;
      }
    }

    &:first-child {
      a {
        @extend .btn-signpost-reversed;
      }
    }

    &:last-child {
      a {
        @extend .btn-signpost;
      }
    }
  }

  li.active {
    a {
      @extend .btn-page-current;

      border: 1px solid theme-color-2022('2022--secondary') !important;
    }
  }
}

a[aria-label='Previous'][aria-disabled='true'],
a[aria-label='Next'][aria-disabled='true'] {
  color: theme-color-2022('2022--white') !important;
  background: theme-color-2022('2022--secondary') !important;
  border-color: transparent !important;
  opacity: 0.7;
}

.shepherd-element {
  background: none;
  outline: none;
  z-index: 1051;
  margin-right: 20px !important;

  .shepherd-content {
    background: theme-color-2022('2022--primary');
    color: theme-color-2022('2022--white');
    padding: 20px;
    border-radius: 4px;
    box-shadow: 5px 5px 13px -1px rgb(0 0 0 / 36%);
    max-width: 450px;
  }

  .shepherd-cancel-icon {
    background: none;
    border: none;
    font-size: 2em;
    position: absolute;
    right: 5px;
    top: -6px;
    color: theme-color-2022('2022--white');
    transition: color linear 200ms;

    &:hover {
      color: theme-color-2022('2022--light-grey');
    }
  }

  .shepherd-button {
    margin-top: 10px;
    background: theme-color-2022('2022--white');
    border: 1px solid transparent;
    border-radius: 0.2rem;
    color: theme-color-2022('2022--secondary-darkest');
    min-height: 2.5rem;
    min-width: 50px;
    transition: all linear 200ms;

    &:hover {
      color: theme-color-2022('2022--white');
      background: transparent;
      border: 1px solid theme-color-2022('2022--white');
    }
  }

  .shepherd-footer {
    text-align: right;
  }
}

body.shepherd-active::after {
  content: '';
  display: block;
  background-color: theme-color-2022('2022--black');
  opacity: 0.3;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
}

div[data-popper-placement='left'] {
  .shepherd-arrow {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid theme-color-2022('2022--primary');
    right: -15px;
    margin-top: -15px;
    z-index: 1051;
  }
}

div[data-popper-placement='top'] {
  margin-bottom: 20px !important;

  .shepherd-arrow {
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-top: 15px solid theme-color-2022('2022--primary');
    bottom: -15px;
    z-index: 1051;
  }
}

div[data-popper-placement='right'] {
  margin-left: 20px !important;

  .shepherd-arrow {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid theme-color-2022('2022--primary');
    z-index: 1051;
    left: -15px !important;
  }
}

.shepherd-modal-overlay-container {
  display: none;
}

.shepherd-adjust-left {
  left: -15px !important;
}

.shepherd-mw-300 {
  max-width: 300px !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.bcFloat {
  bottom: 145px !important;
  top: unset !important;
  z-index: 1000000 !important;
}

#bc-chat-container {
  z-index: 1000100 !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.bcFloatTransition {
  transition: all 750ms ease-in-out !important;
  right: 15px !important;
  left: auto !important;
  margin-right: 0;
  z-index: 1055 !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.bcFloatDrawer {
  margin-right: calc(60% - 15px) !important;
}

@media only screen and (width <= 1600px) {
  /* stylelint-disable-next-line selector-class-pattern */
  .bcFloatDrawer {
    margin-right: calc(65% - 15px) !important;
  }
}

@media only screen and (width <= 1350px) {
  /* stylelint-disable-next-line selector-class-pattern */
  .bcFloatDrawer {
    margin-right: calc(70% - 15px) !important;
  }
}

.text-gold {
  color: #ffd700 !important;
}

.text-purple {
  color: #639 !important;
}

.btn-arrow-left::before {
  content: '';
  position: absolute;
  top: 5px;
  left: -13px;
  width: 26px;
  height: 26px;
  background: inherit;
  border: inherit;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 0 4px 0 0;
  transform: rotate(225deg);
  z-index: 1;
}

.btn-arrow-left {
  position: relative;
  padding-left: 20px;
  padding-right: 25px;
  border-top-left-radius: 0.17rem;
  border-bottom-left-radius: 0.17rem;
}

.footer-hide {
  display: none;
}

.btn-sm {
  border-radius: 0.1rem !important;
}

.btn-edit {
  width: 14rem;
}

.minus-margin {
  margin-top: -3rem;
}

.font-italic {
  font-style: italic !important;
}

.ws-nowrap {
  white-space: nowrap;
}

.border-radius-0 {
  border-radius: 0;
}
