@import './variables';
@import 'mssvariables';

.btn.btn-primary-chevron {
  position: relative;
  width: 100%;
  padding: 14px 16px;
  font-size: 14px;
  text-align: left;
  border-radius: 2px;
  transition: 200ms linear;
  background-color: theme-color-2022('2022--secondary');

  &:disabled,
  &.disabled {
    border: none;
    pointer-events: none !important;
    opacity: 0.3 !important;
  }

  &::after {
    display: block;
    position: absolute;
    right: 16px;
    top: 50%;
    content: '\f054';
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transform: translate(0, -50%);
    transition: inherit;
  }

  &:hover::after {
    transform: translate(6px, -50%);
  }
}

ngb-timepicker {
  &.is-invalid input {
    border-color: map-get($colors, 'danger');
  }

  &.is-valid input {
    border-color: map-get($colors, 'success');
  }
}
