@import 'mssvariables';

.toast-header {
  background-color: unset;
  color: theme-color-2022('2022--white');
  font-weight: bold;
  font-size: 0.9rem;
}

.toast-body {
  color: theme-color-2022('2022--white');
  font-size: 1rem;
  z-index: 1052;
}
