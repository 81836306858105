@import 'mssvariables';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.form-check-input {
  border-color: theme-color-2022('2022--dark') !important;

  &:checked {
    background-color: theme-color-2022('2022--secondary');
  }
}

.form-group {
  margin-bottom: 1rem;
}

formly-group .mb-4 {
  margin-bottom: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: theme-color-2022('2022--light-grey');
  opacity: 1;
}

.form-control-sm {
  border-radius: 0 !important;
}

.custom-radio-label,
.form-select:not(:focus),
.form-control:not(:focus) {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 7.5%);

  .input-group-text {
    background-color: theme-color-2022('2022--info');
    border-color: theme-color-2022('2022--info');
  }
}

formly-wrapper-form-field {
  width: 100%;
}

select.form-select.is-valid,
select.form-select.is-invalid {
  background-position: right 1rem center;
}

/* Custom radio */
.custom-radio-wrapper,
.custom-radio-group {
  .radio-button-item:not(:first-of-type) {
    margin-left: 0.5rem !important;
  }

  label {
    border-radius: 0.2rem !important;
    background-color: theme-color-2022('2022--white');
    color: theme-color-2022('2022--primary');
    min-height: 3rem;
    flex: 1;

    &:active,
    &:hover,
    &:focus,
    &.focus {
      background-color: theme-color-2022('2022--white');
      color: theme-color-2022('2022--primary');
    }

    &.active {
      background-color: theme-color-2022('2022--primary') !important;
      color: theme-color-2022('2022--white');

      &.disabled {
        background-color: theme-color-2022('2022--primary') !important;
        color: theme-color-2022('2022--white');
      }
    }

    &:not(.active) {
      .icon-text {
        color: theme-color-2022('2022--secondary');
      }
    }

    &.disabled {
      background-color: theme-color-2022('2022--white') !important;
      color: theme-color-2022('2022--grey');
      border-color: theme-color-2022('2022--grey');
      cursor: default;
    }

    &:not(:first-of-type) {
      margin-left: 0.5rem !important;
    }

    i:not(:first-of-type) {
      margin-left: 0.1rem;
    }

    .icon-wrapper {
      min-width: 3rem;
    }

    .radio-text {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  ::ng-deep .mb-4 {
    margin-bottom: 0;
  }
}

/* <select>s do not have a readonly attribute, so fake it here */
select.readonly {
  pointer-events: none;
  color: $form-select-disabled-color;
  background-color: $form-select-disabled-bg;
  border-color: $form-select-disabled-border-color;
}

.btn-check:focus-visible + .btn {
  box-shadow: 0 0 0 0.25rem darken(theme-color-2022('2022--secondary-seashell'), 10%) !important;
}
