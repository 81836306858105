@import 'mssvariables';

.modal-dialog {
  max-width: calc(100vw - 150px);
  margin-left: auto;
  margin-right: auto;

  &.modal-sm {
    max-width: 300px;
  }

  &.modal-md {
    max-width: 500px;
  }

  &.modal-lg {
    max-width: 800px;
  }

  &.modal-lgx {
    max-width: 1100px;
  }

  &.modal-xl {
    max-width: calc(100vw - 150px);
  }

  &.modal-75 {
    max-width: 75%;
  }
}

/* stylelint-disable-next-line media-query-no-invalid */
@media (width <= $screen-sm) {
  .modal-dialog {
    max-width: calc(100vw - 150px) !important;
  }
}
