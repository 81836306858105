@import 'mssvariables-legacy';

.bg-dark-green {
  background-color: theme-color('dark-green');
}

.bg-dark-blue {
  background-color: theme-color('dark-blue');
}

.bg-pastel-blue {
  background-color: theme-color('pastel-blue');
}

.bg-light-grey {
  background-color: theme-color('light-grey');
}

.bg-medium-grey {
  background-color: theme-color('medium-grey');
}

.bg-leafy-green-dark {
  background-color: theme-color('leafy-green-dark');
}

.bg-leafy-green-light {
  background-color: theme-color('leafy-green-light');
}

.bg-leafy-green-lightest {
  background-color: theme-color('leafy-green-lightest');
}

.bg-mexican-green {
  background-color: theme-color('mexican-green');
}

/* Text */
.text-dark-green {
  color: theme-color('dark-green');
}

.text-dark-blue {
  color: theme-color('dark-blue');
}

.text-pastel-blue {
  color: theme-color('pastel-blue');
}

.text-light-grey {
  color: theme-color('light-grey');
}

.text-medium-grey {
  color: theme-color('medium-grey');
}

.text-leafy-green-dark {
  color: theme-color('leafy-green-dark');
}

.text-leafy-green-light {
  color: theme-color('leafy-green-light');
}

.text-leafy-green-lightest {
  color: theme-color('leafy-green-lightest');
}

.text-mexican-green {
  color: theme-color('mexican-green');
}

/* Buttons */
.btn-dark-blue {
  color: theme-color('white');
  background-color: theme-color('dark-blue');
  border-color: theme-color('dark-blue');
}

.btn-blue-outline {
  color: theme-color('dark-blue');
  background-color: theme-color('white');
  border-color: theme-color('dark-blue');
}
